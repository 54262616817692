@import "mediaqueries.scss";

.show-nav-btn {
  width: 32px;
  height: 32px;
  float: right;
  display: block;
  z-index: 50;
  @include breakpoint(tablet) {
    display: none;
  }
  cursor: pointer;
  z-index: 110;
  transition: all 0.3s ease;
  right: 30px;
  top: 31px;
  position: absolute;
  z-index: 110;
  transition: all 0.3s ease;

  span,
  span:before,
  span:after {
    height: 1px;
    position: absolute;
    background: $white;
    display: block;
    transition: all 0.5s ease;
    width: 32px;
    border-radius: 10px;
  }
  span {
    margin-top: 14px;
    float: right;
    right: 0;
    &:before {
      top: -9px;
      content: "";
      right: 0;
    }
    &:after {
      bottom: -9px;
      content: "";
      right: 0;
    }
  }
}
.show-nav {
  overflow: hidden;
  .show-nav-btn {
    span {
      background: transparent;
      width: 0;
      &:before {
        top: 0;
        transform: rotate(45deg);
      }
      &:after {
        top: 0;
        transform: rotate(-45deg);
      }
    }
  }
  nav {
    top: 0;
  }
  .show-nav-btn {
    span,
    span:before,
    span:after {
      background-color: $primary;
    }
  }
}
.main-header.scrolled {
  .show-nav-btn {
    span,
    span:before,
    span:after {
      background-color: $primary;
    }
  }
}
