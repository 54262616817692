$primary: #121835;
$secondary: #808285;
$teritary: #00828a;
$quat: #909090;
$page: #f8f8f8;
$black: #000;
$white: #fff;

$footer-color: #353535;
$background: #f5f5f7;
$header-gradient: linear-gradient(
  to bottom,
  rgba(12, 18, 51, 1) 1%,
  rgba(11, 17, 48, 0.8) 60%,
  rgba(11, 17, 48, 0) 100%
);
$gradient: #00000000, #0000000b, #0000009c;

$text-color: $white;
$shadow-color: #00000029;
$gray: #f6f6f7;
$red: #ff0000;

$font-secondary: "Lato", sans-serif;
$font-primary: "Playfair Display", serif;
