@mixin breakpoint($point) {
  @if $point == lgdesktop {
    @media (min-width: 80em) {
      @content;
    }
  }
  @if $point == desktop {
    @media (min-width: 75em) {
      @content;
    }
  } @else if $point == laptop {
    @media (min-width: 64em) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: 51.26em) {
      @content;
    }
  } @else if $point == phablet {
    @media (min-width: 37.5em) {
      @content;
    }
  } @else if $point == mobileonly {
    @media (max-width: 37.5em) {
      @content;
    }
  }
}
