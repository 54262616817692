@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Playfair+Display:ital@0;1&display=swap");
@import "colors.scss";
@import "variables.scss";
@import "mediaqueries.scss";

.font-primary {
  font-family: $font-primary;
}

.elight {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.normal {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

p {
  color: $secondary;
  font-family: $font-secondary;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 1.4rem */
  letter-spacing: 0.02625rem;
}

p strong {
  font-weight: 700;
}

.teritary-header {
  font-family: $font-primary;
  color: $primary;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
  @include breakpoint(phablet) {
    text-align: unset;
  }

  @include breakpoint(tablet) {
    font-size: 26px;
  }

  @include breakpoint(laptop) {
    font-size: 1.125rem;
  }
}

.secondary-header {
  color: $white;
  font-family: $font-primary;
  font-weight: 400;
  font-size: 25px;
  margin: 0;
  line-height: 30px;

  @media (max-width: 380px) {
    font-size: 22px;
    line-height: 25px;
  }

  strong {
    font-weight: 600;
  }
}
.pre-header {
  font-family: $font-secondary;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1.5px;
  margin: 0;
  letter-spacing: 0.16875rem;
  text-transform: uppercase;
}
.primary-header {
  font-family: $font-primary;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  line-height: 40px;

  @include breakpoint(phablet) {
    font-size: 2.5rem;
    line-height: 30px;
  }
}
.banner-header {
  font-family: $font-primary;
  font-size: 2.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.3rem;
  margin: 0;
  @include breakpoint(phablet) {
    font-size: 5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 6rem;
  }
}
