ul > li::marker {
  content: "";
}

.btn {
  box-sizing: border-box;
  outline: none;
  display: block;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.btn-primary,
.btn-primary-o {
  color: $primary;
  background-color: $white;
  font-family: $font-primary;
  font-weight: 600;
  cursor: pointer;
  height: 40px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 17px;
  z-index: 10;
  border-radius: 20px;
  text-decoration: none;
}
.btn-secondary,
.btn-secondary-o {
  color: $primary;
  background-color: $white;
  font-family: $font-primary;
  font-weight: 400;
  cursor: pointer;
  height: 54px;
  width: 100%;
  min-width: 160px;
  max-width: 328px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 21px;
  z-index: 10;
  border-radius: 32px;
  text-decoration: none;
}
.btn-forth {
  padding: 10px 0;
  font-family: $font-secondary;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  cursor: pointer;
  svg {
    margin: 0 0 0 5px;
  }
}
.btn-teritary,
.btn-teritary-o {
  color: $primary;
  font-family: $font-secondary;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.05625rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 22px;
  z-index: 10;
  text-decoration: none;
  border: none;
  outline: none;
  &.bg-tran-primary {
    background-color: transparentize($color: $primary, $amount: 0.8);
  }
  &.color-white {
    color: white;
  }
}
bg-tran-primary .text-center {
  text-align: center;
}
.form-control {
  background-color: $white;
  border-radius: 0;
  width: 100%;
  padding: 8px 0;
  color: $primary;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: $primary;
  font-family: $font-secondary;
  font-size: 1.125rem;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05625rem;
}
.explore-btn a {
  color: $primary;
  font-family: $font-secondary;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16875rem;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  display: inline-block;
  svg {
    margin: 0 8px 0;
  }
}
.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group {
  padding: 14px 8px;
  box-sizing: border-box;
}
.curve-img {
  border-radius: 0.1875rem 1.5625rem 0.1875rem 0.1875rem;
}
@include breakpoint(mobileonly) {
  .container {
    box-sizing: border-box;
  }
}
