//@import "./normalize.scss";
@import "bootstrap/scss/bootstrap-grid.scss";
//@import "bootstrap/scss/bootstrap-grid.scss";
@import "./variables.scss";
@import "./mixin.scss";
//@import "./mediaqueries.scss";

@import "./fonts.scss";
@import "./colors.scss";
@import "./utility.scss";
@import "./mobi-menu.scss";
html {
  margin-top: 0 !important;
}
body {
  background-size: cover;
  margin: 0;
  padding: 0;
}
.main-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 1rem 0;
  z-index: 25;
  transition: all 0.6s ease;
  &.scrolled {
    background: transparentize($color: $white, $amount: 0.1);
    padding: 0.25rem 0;
    .logo-wrap {
      #logo {
        max-height: 70px;
        min-height: 50px;

        .acc {
          fill: $primary;
        }
        .real {
          fill: $primary;
        }
        .tower {
          fill: $secondary;
        }
      }
    }
    nav ul li a {
      color: $secondary;
    }
  }
  .header-inner {
    display: flex;
    justify-content: space-between;
  }
  .logo-wrap {
    width: 6.5rem;
    #logo {
      @media (max-width: 37.5em) {
        height: 70px;
      }
      max-height: 150px;
      transition: all 0.3s ease;
      .acc,
      .real,
      .tower {
        fill: $white;
      }
    }
  }
  .quick-contact {
    display: flex;
    background-color: $primary;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    @include breakpoint(tablet) {
      width: auto;
      flex-direction: row;
      position: static;
      background-color: transparent;
    }
    .cont-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      white-space: nowrap;
      @include breakpoint(tablet) {
        width: auto;
      }
      a {
        width: 100%;
        @include breakpoint(tablet) {
          width: auto;
        }
      }
    }
  }
}

nav {
  display: flex;
  position: fixed;
  top: -100%;
  right: 0;
  left: 0;
  height: 100vh;
  transition: all 0.5s ease;
  background-color: $page;
  @include breakpoint(tablet) {
    position: static;
    height: auto;
    background: none;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include breakpoint(tablet) {
      flex-direction: row;
      height: auto;
      width: auto;
    }
    li {
      display: flex;
      height: auto;
      justify-content: center;
      align-items: center;
      padding: 12px;
      @include breakpoint(tablet) {
        height: 100%;
        padding: 0 12px;
      }
      a {
        font-family: $font-secondary;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.10625rem;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.3s ease;
        @include breakpoint(tablet) {
          font-size: 1.0625rem;
          color: $white;
          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}
.banner-img {
  width: 100%;
  height: 70vh;
  overflow: hidden;
  background-color: $black;
  @include breakpoint(phablet) {
    height: 90vh;
  }
  @include breakpoint(tablet) {
    height: 100vh;
  }
  img {
    width: 100%;
    height: 70vh;
    overflow: hidden;
    object-fit: cover;
    opacity: 0.5;
    @include breakpoint(phablet) {
      height: 90vh;
    }
    @include breakpoint(tablet) {
      height: 100vh;
    }
  }
}
.ban-wrap {
  position: relative;
  overflow: hidden;
}
.banner-home {
  position: absolute;
  height: 70vh;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  @include breakpoint(phablet) {
    height: 90vh;
  }
  @include breakpoint(tablet) {
    height: 100vh;
  }
  .banner-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 70vh;
    padding: 0 0 13% 0;
    @include breakpoint(phablet) {
      height: 90vh;
    }
    @include breakpoint(tablet) {
      height: 100vh;
      padding: 0 0 13% 10%;
    }
  }
}
.search-filter {
  position: relative;
  @include breakpoint(phablet) {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5;

    &:after {
      content: "";
      left: 0;
      width: 50%;
      bottom: 0;
      top: 0;
      background-color: $white;
      position: absolute;
      z-index: -1;
    }
  }
  .filter-wrap {
    background-color: $white;
  }
  form {
    width: 100%;
  }
  .filter-wrap {
    width: 100%;
    max-width: 800px;
    display: flex;
  }

  .inputs-wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 1.2rem;
    position: relative;
    width: 100%;
  }
  form {
    display: flex;
  }
  .form-group {
    position: relative;
    width: 100%;
    @include breakpoint(phablet) {
      width: 50%;
    }
    padding: 8px;
    label {
      color: transparentize($color: $primary, $amount: 0.5);
      font-family: $font-secondary;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.13125rem;
      text-transform: uppercase;
    }
    .select-new {
      width: 100% !important;
      font-family: $font-secondary;
    }
    .select2-container {
      clear: both;
      width: 100% !important;
      font-family: $font-secondary;
      background-color: $white;
      border-radius: 0;
      color: $primary;
      border: none;
      outline: none;
      box-sizing: border-box;
      letter-spacing: 0.05625rem;
    }
    .select2-selection--single {
      border: none;
    }
  }
  .button-wrap-filter {
    display: flex;
    .btn-filter {
      background: $primary;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 7.3rem;
      color: $white;
      @include breakpoint(phablet) {
        min-width: 10.0625rem;
        min-height: 11.375rem;
      }
      span {
        margin: 5px 0 0 0;
        color: $white;
        text-align: center;
        font-family: $font-secondary;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.05625rem;
        @include breakpoint(phablet) {
          font-size: 1.125rem;
        }
      }
    }
  }
}
.sec {
  padding: 50px 0;
  width: 100%;
  @include breakpoint(tablet) {
    padding: 100px 0;
  }
}
.sec-medium {
  padding: 30px 0;
  width: 100%;
  @include breakpoint(tablet) {
    padding: 50px 0;
  }
}
.sec-small {
  padding: 25px 0;
  width: 100%;
  .imag-block {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
.head-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 1.8rem;
  flex-direction: column;
  @include breakpoint(tablet) {
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  }
  .btn-exp {
    margin: 15px 0 0 0;
    @include breakpoint(tablet) {
      margin: 0;
    }
  }
}
.properties-slide {
  a {
    text-decoration: none;
  }
  .image {
    position: relative;
    overflow: hidden;
    border-radius: 0.1875rem 1.5625rem 0.1875rem 0.1875rem;
    box-shadow: 2px 2px 2.8px 0px rgba(0, 0, 0, 0.1);
    &:after {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.04) 54.4%,
        rgba(0, 0, 0, 0.61) 100%
      );
      position: absolute;
      z-index: 1;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    img {
      width: 100%;
      vertical-align: top;
      object-fit: cover;
    }
    .imag-hover-detail {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      right: 0;
      color: $white;
      box-sizing: border-box;
      padding: 20px 25px;
      .detail-line {
        font-family: $font-secondary;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.00875rem;
        padding: 8px 0;
        display: flex;
        border-top: solid 1px $white;
        width: 100%;
      }
      .prop-name {
        font-family: $font-primary;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        padding: 14px 0;
      }
    }
  }
  .bot-location {
    color: $primary;
    text-decoration: none;
    padding: 10px 0;
    svg {
      display: inline-block;
    }
    .loc {
      margin: 0 0 0 8px;
      top: -2px;
      position: relative;
      font-family: $font-secondary;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.00875rem;
    }
  }
}
.txt-block {
  z-index: 5;
  position: relative;
}
.block-gray {
  padding: 25px;
  width: 100%;
  box-sizing: border-box;
  @include breakpoint(tablet) {
    padding: 50px;
    width: auto;
  }

  z-index: 10;
  background: transparentize($color: $white, $amount: 0.1);
  .btn-wrap {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .txt {
    padding-top: 10px;
  }
}
.right-img {
  .block-gray {
    margin: 0;
    @include breakpoint(tablet) {
      margin: 20px -20% 0 0;
    }
  }
}
.left-img {
  .block-gray {
    margin: 0;
    @include breakpoint(tablet) {
      margin: 20px 0 0 -20%;
    }
  }
}
.imag-block {
  img {
    width: 100%;
  }
}
hgroup .pre-header {
  padding: 0 0 8px 0;
}
.blog-link-single {
  a {
    text-decoration: none;
  }
  img {
    width: 100%;
    max-height: 380px;
    object-fit: cover;
  }
  .blog-det {
    .date {
      color: $secondary;
      font-family: $font-secondary;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.00875rem;
      padding: 8px 0 4px 0;
      display: flex;
    }
  }
}
.properties-slide,
#blog-slide {
  width: 100%;
  box-sizing: border-box;
}
.quick-link {
  margin: 0;
  padding: 0 0 15px 0;
  @include breakpoint(tablet) {
    padding: 0 0 15px 15px;
  }
  li {
    display: block;
    padding: 4px 0;
    a {
      color: transparentize($color: $white, $amount: 0.25);
      font-family: $font-primary;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 0.945rem */
      letter-spacing: 0.04375rem;
      text-decoration: none;
      display: inline-block;
      &:hover {
        color: $teritary;
      }
    }
  }
}
.foot-main {
  .img-foot {
    display: flex;
    align-items: flex-end;
    padding: 0 0 20px 0;
    @include breakpoint(tablet) {
      padding: 0 0 30px 0;
    }
  }
  .explore-btn {
    margin-bottom: 20px;
    @include breakpoint(tablet) {
      margin: 0;
    }
  }
}
.foot-links {
  margin: 0;
  padding: 0 0 15px 0;
  li {
    display: block;
    padding: 6px 0;
    a {
      color: $primary;
      font-family: $font-secondary;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.15rem;
      text-transform: uppercase;
      text-decoration: none;
      &:hover {
        color: $teritary;
      }
    }
  }
}
.footer-small {
  padding: 25px 0 80px 0;
  @include breakpoint(tablet) {
    padding: 25px 0;
  }
  .privacy-links,
  .social,
  .foottext {
    padding: 10px 0;
    justify-content: center;
    @include breakpoint(phablet) {
      padding: 0;
    }
  }
  .social {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    li {
      display: flex;
      padding: 0 10px;
      a {
        display: flex;
      }
    }
  }
}
.privacy-links {
  margin: 0;
  padding: 0;
  display: flex;
  @include breakpoint(phablet) {
    justify-content: flex-end;
  }
  li {
    margin: 0;
    padding: 0 5px;
    display: block;
  }
  a {
    color: $primary;
    font-family: $font-secondary;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1125rem;
    text-transform: uppercase;
    text-decoration: none;
  }
}
.foottext {
  @include breakpoint(phablet) {
    justify-content: flex-start;
  }
  display: flex;
  color: $primary;
  font-family: $font-secondary;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.1125rem;
  text-transform: uppercase;
}
.float-section {
  display: none;
  @include breakpoint(phablet) {
    &.show {
      display: block;
    }
  }
  position: fixed;
  top: 30vh;
  z-index: 50;
  right: 0;
  border: solid 1px $secondary;
  border-right: none;
  border-radius: 0.625rem 0rem 0rem 0.625rem;
  background: $white;
  padding: 10px 0;
  box-shadow: 0px 4px 10px 0px transparentize($color: $black, $amount: 0.75);
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
    li {
      display: flex;
      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        text-decoration: none;
        width: 67px;
        color: $primary;
        span {
          color: $primary;
          text-align: center;
          font-family: $font-secondary;
          font-size: 0.57rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.00688rem;
          text-transform: uppercase;
          margin: 5px 0 0 0;
        }
      }
    }
  }
}

.float-section ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column-reverse;
}
